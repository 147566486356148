import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommunicationInterceptorService } from './communication-interceptor/communication-interceptor.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CommunicationInterceptorService,
      multi: true
    }
  ],
  declarations: []
})
export class CommunicationModule { }
