import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionGuard } from '../../../routed/session/session.guard';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { NzMessageService, NzNotificationService } from 'ng-zorro-antd';
import { isString, isNumber } from 'util';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { BusinessError } from '../../../common/model/business-error';
import { ApiHttpResponse } from '../api/base/api-http-response';
const LOCAL_STORAGE_AUTH_TOKEN_KEY = `local_storage_token_key`;

@Injectable({
  providedIn: 'root'
})
export class CommunicationInterceptorService implements HttpInterceptor {
  get token(): string {
    this.$token = localStorage.getItem(`${LOCAL_STORAGE_AUTH_TOKEN_KEY}`);
    return this.$token;
  }

  set token(value: string) {
    const key = `${LOCAL_STORAGE_AUTH_TOKEN_KEY}`;
    this.$token = value;
    if (isString(value)) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }

  }

  private $token: string;
  constructor(private $router: Router
    , private $nzNotificationService: NzNotificationService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // 当有token时，给所有请求头增加本地存在token信息
    if (isString(this.token)) {
      req = req.clone({
        headers: req.headers.set(`token`, this.token)
      });
    }
    // 当请求方式为GET时加上刷新
    if (req.method === 'GET') {
      req = req.clone({
        headers: req.headers
          .set('Cache-Control', 'no-cache')
          .set('Pragma', 'no-cache')
      });
    }

    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          const resp = event as HttpResponse<ApiHttpResponse<any>>;
          const code = resp.body.code;
          const msg = resp.body.msg;
          const data = resp.body.data;

          if (!isNumber(code)) {
            this.$nzNotificationService.error(`业务异常`, `响应内容：${JSON.stringify(resp.body)}`, { nzDuration: 5000 });
            throw new Error('响应内容异常');
          }

          switch (code) {
            case 200: {
              break;
            }
            case 401: {
              this.$router
                .navigateByUrl(`/session/create`)
                .then(() => {
                  console.log(`请先登录`);
                });
              throw new Error(`没有登录，请先登录`);
            }
            default: {
              this.$nzNotificationService.error(`业务异常`, `${msg}`, { nzDuration: 5000 });
              throw new BusinessError(code, msg, data);
            }
          }
        }
      }),
      catchError(error => {
        console.log(error);
        return throwError(error);
      })
    );
  }
}




