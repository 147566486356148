import {Component, HostListener} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  /*解决移动端hover效果改为active
  * bug链接：http://git.liegouchina.com:90/xiangyao/ride/s-web/merge_requests/289
  * */
  @HostListener('touchstart')
  onTouchstart() {}
}
